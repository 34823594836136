<template>
  <ion-page>
    <ion-header mode="ios" class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="start" hide-back-button="true">
          <ion-back-button text="" @click="$emit('closeModal')" default-href="/"></ion-back-button>
        </ion-buttons>
        <ion-title>{{ $t('verify_phone_number') }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="form-phone">
        <div>
          <ion-text
            ><div class="form-phone-text">
              {{ `Code is sent to` + ' ' + '+' + `${countryCode + ' ' + phone}` }}
            </div></ion-text
          >
        </div>
        <div class="form-group">
          <ion-list class="ion-no-padding" lines="none">
            <ion-item :class="otp.isError ? 'item-danger' : ''">
              <div class="form-control">
                <ion-label :class="otp.isError ? 'text-danger' : ''">{{ $t('enter_code') }}</ion-label>
                <ion-input
                  type="tel"
                  maxlength="4"
                  v-model="otp.value"
                  placeholder="Enter verification code"
                  @ionInput="handleRequestOtp"
                  @ionChange="handleChangeOtp"
                  class="input"
                />
              </div>
            </ion-item>
          </ion-list>
          <ion-text v-if="otp.isError">
            <div class="py-1 fs-12px text-danger form-error">
              {{ $t('incorrect_verification_code') }}
            </div>
          </ion-text>
        </div>
        <div class="form-request">
          <ion-text>{{ $t('did_not_receive_code') }}</ion-text>
          <ion-text @click="$emit('handleResentOtp')">{{ $t('request_again') }}</ion-text>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { apolloClient } from '@/main';
import { updateProfile } from '@/modules/b2b/services/graphql';
import { checkUser } from '@/services/shared/graphql';
import debounce from 'lodash.debounce';
import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'VerifyPhoneNumber',
  emits: [
    'closeModal',
    'otpSuspend',
    'handleOpenLoading',
    'handleSuccessVerify',
    'handleResentOtp',
    'handleOpenLoading',
    'handleErrorEmit',
    'onInvalidOtp'
  ],
  props: {
    countryCode: {
      type: String,
      default: ``
    },
    phone: {
      type: String,
      default: ``
    },
    image: {
      type: String,
      default: ``
    },
    gender: {
      type: String,
      default: ``
    },
    email: {
      type: String,
      default: ``
    },
    firstName: {
      type: String,
      default: ``
    },
    lastName: {
      type: String,
      default: ``
    },
    oldImage: {
      type: String,
      default: ``
    },
    tenant_id: {
      type: Number,
      default: null
    }
  },
  setup() {
    const otp = ref({ isError: false, value: `` });
    return {
      otp,
      handleChangeOtp: () => (otp.value.isError = false)
    };
  },
  methods: {
    handleRequestOtp: debounce(async function () {
      if (this.otp.value.length === 4) {
        try {
          await this.$emit('handleOpenLoading', true);
          const { errors } = await apolloClient.mutate({
            mutation: checkUser,
            variables: {
              otp: `${this.otp.value}`,
              mobile: `${this.countryCode + this.phone}`
            }
          });
          if (errors) {
            this.otp.isError = true;
            await this.$emit('handleOpenLoading', false);
          } else {
            this.otp.isError = false;
            this.handleUpdateProfile();
          }
        } catch (e) {
          if (e.message.includes(`too many`)) {
            this.$emit('otpSuspend');
          } else {
            await this.$emit('handleOpenLoading', false);
            this.otp.isError = true;
          }
        }
      }
    }, 500),
    async handleUpdateProfile() {
      try {
        const items = {
          firstName: this.firstName,
          lastName: this.lastName,
          gender: this.gender,
          email: this.email,
          mobile: `${this.countryCode}${this.phone}`,
          tenantId: this.tenant_id,
          ...(this.oldImage ? { image: this.image } : {})
        };
        await apolloClient.query({
          query: updateProfile,
          variables: items
        });
        await apolloClient.query;
        await this.$emit('handleOpenLoading', false);
        await this.$emit('handleSuccessVerify');
      } catch (e) {
        await this.$emit('handleOpenLoading', false);
        await this.$emit('handleErrorEmit', e);
      }
    }
  }
});
</script>

<style lang="scss" scoped>
ion-title {
  color: #181818;
  font-size: 17px;
  line-height: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: 600;
}
ion-back-button {
  --color: #92949c !important;
  font-size: 14px;
  width: 36px;
}
ion-toolbar,
ion-header {
  --min-height: 44px;
  --padding-top: 0px !important;
  --padding-start: 0px !important;
  --padding-right: 0px !important;
  --padding-end: 20px !important;
  --padding-bottom: 0px !important;
}
.form-phone {
  width: 100%;
  &-text {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    color: #181818;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }
}
ion-item {
  border: none;
  --highlight-color-focused: none;
  border-bottom: 1px solid #0000001a;
  --inner-padding-bottom: 0;
  --inner-padding-end: 0;
  --inner-padding-start: 0;
  --inner-padding-top: 0;
}
ion-input {
  --padding-top: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-bottom: 0px;
}
.form-group {
  margin: 0px 20px;
  margin-top: 46px;
  margin-bottom: 36px;
  & > ion-list {
    margin: 0px 20px;
    & > ion-item {
      width: 100%;
      & > .form-control {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: 20px;
        & > ion-label {
          font-size: 14px;
          line-height: 17px;
          color: #181818;
        }
        & > ion-input {
          width: 100%;
          margin-top: 13px;
          margin-bottom: 5px;
          font-size: 17px;
          line-height: 20px;
          color: #181818;
        }
      }
    }
  }
  & > ion-text {
    display: flex;
    justify-content: center;
  }
}
.form-request {
  display: flex;
  justify-content: center;
  & > ion-text:nth-child(1) {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500px;
    color: #181818;
  }
  & > ion-text:nth-child(2) {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500px;
    color: #00676a;
    cursor: pointer;
    margin-left: 3px;
  }
}
.item-danger {
  border-bottom: 1px solid red !important;
}
</style>
